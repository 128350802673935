import request from '@/utils/request'

export function add(data) {
    return request({
        url: 'api/evaluation/final/score',
        method: 'post',
        data
    })
}

export function genScoreData(data) {
    return request({
        url: 'api/evaluation/final/score/gen',
        method: 'post',
        data
    })
}

export function genScoreRank(data) {
    return request({
        url: 'api/evaluation/final/score/rank',
        method: 'post',
        data
    })
}

export function del(ids) {
    return request({
        url: 'api/evaluation/final/score/',
        method: 'delete',
        data: ids
    })
}

export function edit(data) {
    return request({
        url: 'api/evaluation/final/score',
        method: 'put',
        data
    })
}

export function getStuScoreLogNoPage(params) {
    return request({
        url: 'api/evaluation/final/score/getStuInfo',
        method: 'get',
        params
    })
}


export function findById(id) {
    return request({
        url: 'api/evaluation/final/score/byId/' + id,
        method: 'get'
    })
}


export default { add, edit, del, genScoreData, genScoreRank, getStuScoreLogNoPage, findById }
