<template>
  <div id="bbForm">
    <van-form ref="form" class="form" style="margin-bottom:65px">
      <!-- 基本信息 -->
      <van-cell-group title="基本信息">
        <van-field v-model="form.xn" label="学年" readonly> </van-field>
        <van-field v-model="form.xq" label="学期" readonly>
          <template #input>
            {{ codeConvertLabel(form.xq, dict.xj_xqgl) }}
          </template>
        </van-field>
        <van-field v-model="form.stuName" label="姓名" readonly> </van-field>
        <van-field v-model="form.stuNo" label="学号" readonly> </van-field>
        <van-field v-model="form.collName" label="院系" readonly> </van-field>
        <van-field v-model="form.specName" label="专业" readonly> </van-field>
        <van-field v-model="form.className" label="班级" readonly> </van-field>
        <van-field v-model="form.gradeNo" label="年级" readonly> </van-field>
      </van-cell-group>

      <!-- 负责学生-->
      <van-cell-group title="成绩信息">
        <van-field v-model="form.score" label="成绩" readonly />
        <van-field v-model="form.schoolRank" label="校级排名" readonly />
        <van-field v-model="form.collRank" label="院系排名" readonly />
        <van-field v-model="form.specRank" label="专业排名" readonly />
        <van-field v-model="form.classRank" label="班级排名" readonly />
      </van-cell-group>
    </van-form>
  </div>
</template>
<script>


import crudEvaluationFinalScore from '@/api/modules/evaluation/evaluationFinalScore'
import { codeConvertLabel } from "@/utils";

export default {
  name: "SumDetail",
  dicts: ['xj_xqgl'],
  data() {
    return {
      form: {},
    }
  },
  async created() {
    if (this.$route.query && this.$route.query.id) {
      await crudEvaluationFinalScore.findById(this.$route.query.id).then((res) => {
        this.form = { ...res }
      })
    }
  },
  methods: {
    codeConvertLabel,
  },
}
</script>

<style lang="scss" scoped>
#bbForm {
  .form {
    ::v-deep.radio-other-info {
      .van-cell {
        .van-field__label {
          width: 11.2em;
        }
      }
    }

    .van-field__label {
      width: 110px;
    }
    .van-cell {
      font-size: 15px;
      line-height: 32px;
      height: 50px;
    }
    .textarea-style {
      height: 100%;
    }
  }

  .wj-title {
    text-align: center;
    line-height: 55px;
    font-size: 17px;
    font-weight: bold;
    height: 40px;
    background-color: #fff;
  }
  .wj-search {
  }
  .stu-card {
    margin-top: 18px;
  }
  .wj-body {
    overflow-y: scroll;
    height: calc(80vh - 100px);
    padding: 0 18px;
  }
}
</style>